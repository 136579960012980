/**
 * Created by osirvent on 17/02/2017.
 */
var DocumentsViewModeDefinition = function(types, active) {
    this.types = types;
    this.active = active;
    this.showDefault = active == -1 ? true : false;
    this.vmRoot = [];
    this.selected = undefined;
    this.expanded = undefined;
    this.columns = [
        { id: 'name', label: 'global.literals.name', type: 'text', column_type: 'NORMAL'  },
        { id: 'type', label: 'global.literals.type', type: 'translate', prefix: 'global.fileTypes.', column_type: 'NORMAL' }
    ]

}